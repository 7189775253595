import { SupportedLanguages } from "conf/languages";

export const globalInitialState = {
  playerEmail: "",
  language: SupportedLanguages.english,
  numberOfWordsPlayed: 0,
  numberOfWordsWon: 0,
  showEmailPopup: false,
  showInstructionsSequence: true,
};
