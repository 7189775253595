import { getUnauthenticatedApi, postUnauthenticatedApi } from "../apiGenerator";
import { Language, Word } from "api/types/api";
import { SupportedLanguages } from "conf/languages";

const GetLanguageAPI = (language: SupportedLanguages) =>
  getUnauthenticatedApi<Language>({
    url: "/language",
    params: {
      lang: language,
    },
  });

const GetAWordAPI = ({
  language,
  difficulty,
}: {
  language: SupportedLanguages;
  difficulty?: number;
}) =>
  getUnauthenticatedApi<Word>({
    url: "/word",
    params:
      difficulty !== undefined
        ? {
            lang: language,
            difficulty,
          }
        : {
            lang: language,
          },
  });

const RegenerateWordAPI = (prevWordId: string, timePlayed: number, difficulty?:number) =>
  getUnauthenticatedApi<Word>({
    url: "/regenerate-word",
    params: difficulty !== undefined ? {
      prevWordId,
      timePlayed,
      difficulty
    } : {
      prevWordId,
      timePlayed,
    },
  });

const RequestHintAPI = (wordId: string) =>
  getUnauthenticatedApi<Word>({
    url: "/request-hint",
    params: {
      wordId,
    },
  });

const GetNextWordAPI = (prevWordId: string, timePlayed: number,difficulty?:number) =>
  getUnauthenticatedApi<Word>({
    url: "/get-next-word",
    params: 
    difficulty !== undefined ? {
      prevWordId,
      timePlayed,
      difficulty
    } :{
      prevWordId,
      timePlayed,
    },
  });

const RegisterUserAPI = (email: string) =>
  postUnauthenticatedApi<{ email: string }, { success: boolean }>({
    url: "/user/register",
    body: {
      email,
    },
  });

export const client = {
  // ! GET
  GetLanguageAPI,
  GetAWordAPI,
  GetNextWordAPI,
  RegenerateWordAPI,
  RequestHintAPI,

  // ! POST
  RegisterUserAPI,
};
