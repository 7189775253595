import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { MainLayout } from "layouts/MainLayout";
import { UisToggleOn, UisToggleOff } from "@iconscout/react-unicons-solid";

import "./styles.scss";
import { useEffect, useState } from "react";
import { Language, Word } from "api/types/api";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, RootStateType } from "store/types";
import { client } from "api";
import { toast } from "react-toastify";
import { UilCheck, UilPause, UilPlay, UilRedo } from "@iconscout/react-unicons";
import {
  incrementNumberOfWordsPlayed,
  incrementNumberOfWordsWon,
  setEmailPopup,
  setInstructionsSequence,
} from "store/actions";
import { EmailPopup } from "components/popups/EmailPopup";
import Tour from "reactour";
import { instructionsSteps } from "conf/instructionsSteps";
import { SharePopup } from "components/popups/SharePopup";

const HomeContent = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    language,
    numberOfWordsPlayed,
    numberOfWordsWon,
    playerEmail,
    showEmailPopup,
    showInstructionsSequence,
  } = useSelector<RootStateType, GlobalState>((state) => state.global);
  const [languageObj, setLanguageObj] = useState<Language | null>(null);
  const [targetWord, setTargetWord] = useState<Word | null>(null);
  const [timer, setTimer] = useState<number>(0);
  const [_timer, set_timer] = useState<NodeJS.Timer | null>(null);
  const [revealedHints, setRevealedHints] = useState<number[]>([]);
  const [isBeginnerModeOn, setBeginnerMode] = useState(true);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const dispatch = useDispatch();

  const getLanguage = async () => {
    const { response, errors } = await client.GetLanguageAPI(language);
    if (errors) {
      errors.forEach((error) => {
        toast.error(error.message);
      });
      return;
    }
    if (response) {
      setLanguageObj((_) => response);
    }

    if (targetWord == null || targetWord.language !== language) {
      await getTargetWord(isBeginnerModeOn ? 4 : 1);
    }
  };

  const getTargetWord = async (func: 1 | 2 | 3 | 4 = 1) => {
    resetTimer();
    const { response, errors } = await (func === 1
      ? client.GetAWordAPI({ language })
      : (func === 2 && targetWord) || (func === 3 && targetWord && timer === 0)
      ? client.RegenerateWordAPI(
          targetWord._id,
          timer,
          isBeginnerModeOn ? 0 : undefined
        )
      : func === 3 && targetWord
      ? client.GetNextWordAPI(
          targetWord._id,
          timer,
          isBeginnerModeOn ? 0 : undefined
        )
      : func === 4
      ? client.GetAWordAPI({ language, difficulty: 0 })
      : { response: null, errors: [{ message: "Error Executing On Client" }] });

    if (errors) {
      errors.forEach((error) => {
        toast.error(error.message);
      });
      return;
    }

    resetTimer();
    setTargetWord((_) => response);
    setRevealedHints((_) => []);
    set_timer(null);
    setTimer(0);
  };

  const startTimer = async () => {
    if (_timer != null) {
      return;
    }
    if (timer === 0) {
      dispatch(incrementNumberOfWordsPlayed());
    }

    set_timer(
      setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000)
    );
  };

  const stopTimer = () => {
    if (_timer == null) {
      return;
    }
    clearInterval(_timer);
    set_timer(null);
  };

  const resetTimer = () => {
    stopTimer();
    setTimer(0);
  };

  const nextWord = () => {
    setShowSharePopup(false);
    getTargetWord(3);
    targetWord && timer > 0 && dispatch(incrementNumberOfWordsWon());
  };

  const onSolved = () => {
    stopTimer();
    if (!_timer || !targetWord || timer < 10) {
      return getTargetWord(2);
    }
    setShowSharePopup(true);
  };

  const regenerateWord = () => {
    getTargetWord(2);
  };

  const requestHint = async (idx: number) => {
    if (!_timer || !targetWord || revealedHints.includes(idx)) {
      return;
    }

    const { response, errors } = await client.RequestHintAPI(targetWord._id);
    if (errors) {
      errors.forEach((error) => {
        toast.error(error.message);
      });
      return;
    }
    if (response) {
      setRevealedHints((prev) => (prev.includes(idx) ? prev : [...prev, idx]));
    }
  };

  const onRequestCloseInstructionsSequence = () => {
    dispatch(setInstructionsSequence(false));
  };

  const toggleBeginnerMode = () => {
    if (!isBeginnerModeOn) {
      getTargetWord(4);
    } else {
      getTargetWord(2);
    }

    setBeginnerMode((prev) => !prev);
    set_timer((_) => null);
    setTargetWord((_) => null);
  };

  // useEffect(() => {
  //   // beginner mode use effect
  //   if (isBeginnerModeOn) {
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isBeginnerModeOn]);

  useEffect(() => {
    // get language on first render and when language changes
    const timer = setTimeout(() => {
      getLanguage();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (numberOfWordsPlayed === 0) {
      // show first time popup
      return;
    }

    if (numberOfWordsWon >= 5 && !playerEmail) {
      dispatch(setEmailPopup(true));
      return;
    } else {
      dispatch(setEmailPopup(false));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfWordsWon, numberOfWordsPlayed, playerEmail]);

  return (
    <MainLayout>
      {process.env.NODE_ENV === "development" ? (
        <div className="devTools">
          <p className="timer">timer value: {timer}</p>
        </div>
      ) : null}
      {showSharePopup && (
        <SharePopup
          show={showSharePopup}
          word={targetWord}
          timerValue={timer}
          onHide={nextWord}
        />
      )}
      <EmailPopup show={showEmailPopup} />
      <Tour
        steps={instructionsSteps.map((step) => ({
          ...step,
          content: t(step.content),
        }))}
        isOpen={showInstructionsSequence}
        onRequestClose={onRequestCloseInstructionsSequence}
        accentColor="#480183"
        showNavigation={false}
      />
      <div className="gameWrapper">
        <div className="leftSection">
          <div
            className={`targetWord ${
              _timer || (timer > 0 && !isBeginnerModeOn) ? "revealed" : ""
            }`}
          >
            <p className="descriptionLabels">{t("targetWord")}</p>
            <p className="mainTargetWord">{targetWord?.value}</p>
            <p className="requireStartTimer">{t("requireStartTimer")}</p>
          </div>
          {_timer == null ? (
            <div className="actions">
              <button
                className="actionButton"
                onClick={_timer ? stopTimer : startTimer}
              >
                {_timer == null ? (
                  <UilPlay className="icon" />
                ) : (
                  <UilPause className="icon" />
                )}{" "}
                {t("homePage.startGame")}
              </button>
            </div>
          ) : (
            <div className="actions">
              <button className="actionButton" onClick={regenerateWord}>
                <UilRedo className="icon" /> {t("homePage.giveUp")}
              </button>
              <button className="actionButton" onClick={onSolved}>
                <UilCheck className="icon" /> {t("homePage.solved")}
              </button>
            </div>
          )}

          <button className="beginnerModeButton" onClick={toggleBeginnerMode}>
            {t("homePage.easier")}
            <span className="beginnerModeButtonToggleIcon">
              {!isBeginnerModeOn ? (
                <UisToggleOn size={40} className="icon turnOff" />
              ) : (
                <UisToggleOff size={40} className="icon turnOn" />
              )}
            </span>

            {t("homePage.harder")}
          </button>

          {targetWord?.hintWords.length ? (
            <div className="hintWords">
              <p className="descriptionLabels">{t("hintWords")}</p>

              <div className="mainHintWords">
                {targetWord?.hintWords.map((word, idx) => (
                  <div
                    key={idx}
                    className="hintWord"
                    onClick={() => requestHint(idx)}
                  >
                    <span
                      className={
                        revealedHints.includes(idx) ? "revealed" : "hidden"
                      }
                    >
                      {word}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="rightSection">
          <div className="bankOfWordsWrapper">
            <p className="descriptionLabels">
              {t("homePage.bankOfWordsDesc")}
              {isBeginnerModeOn
                ? t("homePage.beginnerModeBankWordsDesc")
                : null}
            </p>
            <div
              className={`bankOfWords ${
                isBeginnerModeOn && _timer ? "activateHighlighting" : ""
              }`}
            >
              {languageObj?.wordsBank.map((word, idx) => (
                <div
                  key={idx}
                  className={`bankWord ${
                    targetWord?.highlightedWords?.includes(word.toLowerCase())
                      ? "highlighted"
                      : ""
                  }`}
                >
                  {word}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const HomeHeader = (): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <Helmet>
      <title>{t("PDT")}</title>
    </Helmet>
  );
};

const Home = (): JSX.Element => {
  return (
    <>
      <HomeHeader />
      <HomeContent />
    </>
  );
};

export { Home };
