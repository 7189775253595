export enum GlobalActionTypes {
  SET_GLOBAL = "SET_GLOBAL",
  SET_LANGUAGE = "SET_LANGUAGE",
  INCREMENT_NUMBER_OF_WORDS_PLAYED = "INCREMENT_NUMBER_OF_WORDS_PLAYED",
  INCREMENT_NUMBER_OF_WORDS_WON = "INCREMENT_NUMBER_OF_WORDS_WON",
  TOGGLE_EMAIL_POPUP = "TOGGLE_EMAIL_POPUP",
  SET_EMAIL_POPUP = "SET_EMAIL_POPUP",
  TOGGLE_INSTRUCTIONS_SEQUENCE = "TOGGLE_INSTRUCTIONS_SEQUENCE",
  SET_INSTRUCTIONS_SEQUENCE = "SET_INSTRUCTIONS_SEQUENCE",
}
