import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { setInstructionsSequence, setLanguage } from "store/actions";
import { SupportedLanguages } from "conf/languages";
import { Logo } from "assets/logo";
import { useTranslation } from "react-i18next";
import { GlobalState, RootStateType } from "store/types";

export const MainNav = (): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useSelector<RootStateType, GlobalState>(
    (state) => state.global
  );
  const dispatch = useDispatch();

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setLanguage(e.target.value as SupportedLanguages));
  };

  const showInstructionsSequence = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(setInstructionsSequence(true));
  };

  return (
    <div className="mainNav">
      <div className="mainNav__leftSection">
        <a href="/" className="mainNav__leftSection__logo">
          <Logo height={40} />
        </a>
      </div>
      <div className="mainNav__rightSection">
        <div className="mainNav__rightSection__links">
          {/* <a href="/">{t("play")}</a> */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={showInstructionsSequence}>
            {t("howTo")}
          </a>
          <a
            href="https://discord.gg/eMsapSaWxP"
            target="_blank"
            rel="noreferrer"
            className="descriptionLabels"
          >
            {t("joinUsOnDiscord")}
          </a>
        </div>

        <div className="mainNav__rightSection__languages">
          <select
            name="lang"
            id="lang"
            onChange={onLanguageChange}
            value={language}
          >
            {Object.values(SupportedLanguages).map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
