import { MainNav } from "components/layout/MainNav";
import "./styles.scss";
export const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="mainLayout">
      <MainNav />
      <div className="body">{children}</div>
    </div>
  );
};
