import { Logo } from "assets/logo";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { client } from "api";
import { toast } from "react-toastify";
import { setGlobal } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, RootStateType } from "store/types";
interface Props extends React.PropsWithChildren {
  show: boolean;
}

export const EmailPopup: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation();
  const state = useSelector<RootStateType, GlobalState>(
    (state) => state.global
  );
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    // email validation
    // eslint-disable-next-line no-useless-escape
    if (!email || !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      toast.error(t("emailPopup.error0"));
      return;
    }

    setLoading(true);

    const { response, errors } = await client.RegisterUserAPI(email);

    setLoading(false);

    if (errors) {
      errors.forEach((error) => {
        toast.error(error.message);
      });
      return;
    }

    if (response) {
      dispatch(
        setGlobal({
          ...state,
          playerEmail: email,
          showEmailPopup: false,
        })
      );
    }
  };
  return show ? (
    <div className="emailPopupWrapper">
      <div className="emailPopup">
        <div className="logo">
          <Logo height={46} />
        </div>
        <div className="description">
          <p>{t("emailPopup.d0")}</p>
          <p>{t("emailPopup.d1")}</p>
          <p>{t("emailPopup.d2")}</p>
          <p>{t("emailPopup.d3")}</p>
        </div>
        <div className="emailInput">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="example@domain.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="buttons">
          <button className="submit" onClick={onSubmit}>
            {t("emailPopup.submit")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
