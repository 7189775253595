import { getHostDomain } from "api/getHost";
import { Word } from "api/types/api";
import { SocialMedia } from "conf/socialMedia";
import { TFunction } from "i18next";

interface Props {
  socialMedia: SocialMedia;
  word: Word;
  fasterThanPercentage: number;
  t: TFunction;
}

export const getShareLink = ({
  socialMedia,
  word,
  fasterThanPercentage,
  t,
}: Props) => {
  const fullDomain = getHostDomain();

  const text = encodeURIComponent(
    `${t("shareLink.d0")} ${fasterThanPercentage}% ${t("shareLink.d1")}\n${t(
      "shareLink.d2"
    )}\n${t("shareLink.d3")}\n`
  );

  switch (socialMedia) {
    case SocialMedia.Facebook:
      return `https://www.facebook.com/sharer/sharer.php?u=${fullDomain}&quote=${text}`;
    case SocialMedia.Twitter:
      return `https://twitter.com/intent/tweet?text=${text}&url=${fullDomain}`;
    case SocialMedia.Whatsapp:
      return `https://wa.me/?text=${text} ${fullDomain}`;
    case SocialMedia.Snapchat:
      return `https://www.snapchat.com/share?url=${fullDomain}&title=${text}`;
    case SocialMedia.Telegram:
      return `https://telegram.me/share/url?url=${fullDomain}&text=${text}`;
  }

  return "/";
};
