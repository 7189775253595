import { Word } from "api/types/api";

interface GetFasterThanPercentageProps {
  word: Word;
  timerValue: number;
}
export const getFasterThanPercentage = ({
  word,
  timerValue,
}: GetFasterThanPercentageProps) => {
  const timeStampsKeys = Object.keys(word.solvingTimesStamps).map((key) =>
    parseInt(key, 10)
  );

  const playersWhoSolvedTheWord = word.numberOfTimesWon;
  const playersWhoSolvedTheWordFasterThanYou = timeStampsKeys
    .filter((time) => time < timerValue)
    .reduce((acc, curr) => {
      acc += word.solvingTimesStamps[`${curr}`];
      return acc;
    }, 0);

  const fasterThanPercentage = Math.round(
    100 - (playersWhoSolvedTheWordFasterThanYou / playersWhoSolvedTheWord) * 100
  );

  return fasterThanPercentage;
};
