export const instructionsSteps = [
  {
    selector: ".targetWord",
    content: "instructionsSequence.targetWord",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.0",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.1",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.2",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.3",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.4",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.5",
  },
  {
    selector: ".bankOfWords",
    content: "instructionsSequence.bankOfWords.6",
  },

  {
    selector: ".hintWords",
    content: "instructionsSequence.hintWords.0",
  },
  {
    selector: ".hintWords",
    content: "instructionsSequence.hintWords.1",
  },
  {
    selector: ".actions",
    content: "instructionsSequence.won",
  },

  // {
  //   selector: ".timerWrapper",
  //   content:
  //     "This is the timer, you need to guess the word in the shortest time possible",
  // },
  // {
  //   selector: ".actions",
  //   content:
  //     "This is the actions section, you can start, stop, reset the timer, regenerate the word or get a hint",
  // },
  // {
  //   selector: ".hintWords",
  //   content:
  //     "This is the hints section, you can get hints for the word by clicking on the hint button",
  // },
  // {
  //   selector: "#lang",
  //   content:
  //     "This is the language selector, you can select the language of the word you want to guess",
  // },
];
