import { CombinedState, Middleware, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "store/reducers";

// middlewares
import logger from "redux-logger";
import { RootStateType } from "./types";

declare global {
  interface Window {
    // eslint-disable-next-line
    __REDUX_DEVTOOLS_EXTENSION__: (...a: any) => any | undefined;
  }
}

const middlewares: Middleware[] = [logger];

const enhancer = applyMiddleware(...middlewares);

const persistConfig: PersistConfig<CombinedState<RootStateType>> = {
  key: "root",
  storage: storage,
  whitelist: ["global"],
  timeout: 10000,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
  enhancers: [enhancer],
});

export const persistor = persistStore(store);
