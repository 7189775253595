export function getHost(): string {
  return process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/v1"
      // "http://192.168.2.101:5000/api/v1"
    : "/api/v1";
}

export function getHostDomain(): string {
  return "https://person-do-thing-496f3.ondigitalocean.app";
}
