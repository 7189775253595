import { GlobalActionTypes } from "./actionTypes";
import { GlobalState, ReduxAction } from "./types";

export const setGlobal = (payload: GlobalState): ReduxAction => ({
  type: GlobalActionTypes.SET_GLOBAL,
  payload,
});

export const setLanguage = (language: GlobalState["language"]) => ({
  type: GlobalActionTypes.SET_LANGUAGE,
  payload: {
    language: language,
  },
});

export const incrementNumberOfWordsPlayed = () => ({
  type: GlobalActionTypes.INCREMENT_NUMBER_OF_WORDS_PLAYED,
});

export const incrementNumberOfWordsWon = () => ({
  type: GlobalActionTypes.INCREMENT_NUMBER_OF_WORDS_WON,
});

export const toggleEmailPopup = () => ({
  type: GlobalActionTypes.TOGGLE_EMAIL_POPUP,
});

export const setEmailPopup = (showEmailPopup: boolean) => ({
  type: GlobalActionTypes.SET_EMAIL_POPUP,
  payload: {
    showEmailPopup,
  },
});

export const toggleInstructionsSequence = () => ({
  type: GlobalActionTypes.TOGGLE_INSTRUCTIONS_SEQUENCE,
});

export const setInstructionsSequence = (showInstructionsSequence: boolean) => ({
  type: GlobalActionTypes.SET_INSTRUCTIONS_SEQUENCE,
  payload: {
    showInstructionsSequence,
  },
});
