export enum SupportedLanguages {
  english = "en",
  french = "fr",
  german = "de",
  spanish = "es",
  norwegian = "no",
  polish = "pl",
  fancy = "fancy",
  pirate = "pirate",
  esperanto = "eo",
}
