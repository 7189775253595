import i18next, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// english, french, german, spanish, norwegian, polish, fancy, doge, pirate
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import de from "./locales/de.json";
import es from "./locales/es.json";
import no from "./locales/no.json";
import pl from "./locales/pl.json";
import fancy from "./locales/fancy.json";
import doge from "./locales/doge.json";
import pirate from "./locales/pirate.json";

const resources: Resource = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  no: {
    translation: no,
  },
  pl: {
    translation: pl,
  },
  fancy: {
    translation: fancy,
  },
  doge: {
    translation: doge,
  },
  pirate: {
    translation: pirate,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
