import { SVGProps } from "react";
import "./logo.scss";
export const Logo = ({ ...params }: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <div className="logoWrapper">
      <div className="line primary-color">
        <span>Person</span>
      </div>
      <div className="line gray-color">
        <span>Do</span>
        <span className="black-color">Thing</span>
      </div>
    </div>
  );
};
// export const Logo = ({ ...params }: SVGProps<SVGSVGElement>): JSX.Element => {
//   return (
//     <svg
//       width="135"
//       height="53"
//       viewBox="0 0 135 53"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       {...params}
//     >
//       <path
//         d="M3.52062 2.51738C6.91321 1.43851 10.3421 0.899067 13.8074 0.899067C17.2727 0.899067 19.9383 1.47447 21.8043 2.62527C22.2404 3.91993 22.4585 5.15464 22.4585 6.32942C22.4585 9.08656 21.3075 11.4002 19.0054 13.2702C16.7033 15.1163 13.7347 16.4469 10.0998 17.2621L9.22744 25.3177L5.59252 25.6773L3.52062 2.51738ZM15.4431 7.37234C15.4431 5.52626 14.6798 4.14769 13.1531 3.23664C12.717 3.23664 12.1838 3.32055 11.5538 3.48838L10.3543 14.8166C11.9294 13.9535 13.1653 12.8387 14.0619 11.4721C14.9827 10.1055 15.4431 8.73892 15.4431 7.37234Z"
//         fill="#480183"
//       />
//       <path
//         d="M42.12 25.1738L27.9075 25.4975L26.1991 1.79813L42.2291 1.29466L42.12 3.70415L34.4504 4.60322L33.5053 12.0475L41.2477 11.9756L41.4294 15.2122L33.2145 14.6008L32.2331 22.6205L42.2654 21.4697L42.12 25.1738Z"
//         fill="#480183"
//       />
//       <path
//         d="M56.1315 16.0393C55.6953 16.1832 55.3439 16.2551 55.0774 16.2551L54.096 25.3177L50.461 25.6773L48.3891 2.51738C49.6735 2.10981 51.3698 1.7382 53.478 1.40254C55.6105 1.06689 57.6461 0.899067 59.5847 0.899067C62.6865 0.899067 65.1703 1.47447 67.0363 2.62527C67.4725 3.91993 67.6906 5.1067 67.6906 6.18557C67.6906 9.20644 65.9337 11.6998 62.4199 13.6658L68.5266 25.1379L65.9821 26.5404L56.1315 16.0393ZM60.6752 7.15656C60.6752 5.45433 59.9118 4.14769 58.3852 3.23664C57.8036 3.23664 57.1493 3.35651 56.4223 3.59626L55.3318 13.9535C57.0039 13.2103 58.3125 12.2153 59.2575 10.9686C60.2026 9.69792 60.6752 8.42724 60.6752 7.15656Z"
//         fill="#480183"
//       />
//       <path
//         d="M72.1305 9.35029C72.1305 5.9698 73.948 2.85304 77.5829 0L84.2348 4.38744C81.23 6.2575 79.7275 8.23545 79.7275 10.3213C79.7275 11.2084 80.0304 12.1434 80.6363 13.1264C81.2421 14.1093 81.8479 15.0803 82.4537 16.0393C83.0838 16.9983 83.3988 17.8974 83.3988 18.7365C83.3988 22.2129 80.9755 24.8981 76.129 26.7922L74.3842 24.4546C75.5716 23.8073 76.3955 23.1839 76.8559 22.5845C77.3164 21.9852 77.5466 21.3258 77.5466 20.6066C77.5466 19.8873 77.2558 19.0842 76.6742 18.1971C76.1168 17.286 75.4989 16.4589 74.8204 15.7157C74.1661 14.9724 73.5482 14.0254 72.9666 12.8746C72.4092 11.7238 72.1305 10.549 72.1305 9.35029Z"
//         fill="#480183"
//       />
//       <path
//         d="M105.596 17.7296C105.596 19.1921 105.475 20.4148 105.232 21.3978C104.99 22.3568 104.554 23.2079 103.924 23.9511C102.712 25.3417 100.362 26.037 96.8721 26.037C93.4068 26.037 90.717 25.2578 88.8026 23.6994C88.3906 21.6855 88.1847 19.6955 88.1847 17.7296C88.1847 13.7497 88.9359 10.1894 90.4383 7.04868C91.965 3.90794 93.734 1.67826 95.7453 0.359626C98.5805 1.51043 100.931 3.66819 102.797 6.8329C104.663 9.97364 105.596 13.6059 105.596 17.7296ZM101.198 16.1113C101.198 12.4191 100.58 9.25438 99.3439 6.61713C96.9933 8.75091 95.1516 11.2203 93.8188 14.0254C92.5102 16.8305 91.8559 19.5876 91.8559 22.2968C92.6799 22.4647 93.6613 22.5486 94.8002 22.5486C97.0296 22.5486 98.944 22.2729 100.543 21.7214C100.98 19.8514 101.198 17.9813 101.198 16.1113Z"
//         fill="#480183"
//       />
//       <path
//         d="M130.745 1.43851L129.182 25.1738L125.91 25.7133L114.133 6.43731H113.842L113.951 25.1738L110.316 25.3537L111.298 1.79813L120.094 1.25869L126.456 19.204H126.746L127.11 1.61832L130.745 1.43851Z"
//         fill="#480183"
//       />
//       <path
//         d="M0 28.205C3.56222 27.0781 6.93058 26.5147 10.1051 26.5147C13.3038 26.5147 15.9088 26.9942 17.9201 27.9532C18.7198 29.8952 19.1197 31.9211 19.1197 34.0309C19.1197 36.884 18.4169 39.6051 17.0114 42.1944C15.6301 44.7838 13.6067 46.9415 10.9411 48.6677C8.29973 50.37 5.34333 51.269 2.0719 51.3649L0 28.205ZM12.6495 36.4764C12.6495 34.7262 12.383 33.1678 11.8498 31.8012C11.3409 30.4347 10.6018 29.4757 9.63253 28.9242C9.19634 28.9242 8.66322 29.0081 8.03317 29.176L6.03396 48.3441C8.21491 47.457 9.86274 45.9106 10.9775 43.7049C12.0922 41.4752 12.6495 39.0657 12.6495 36.4764Z"
//         fill="#333333"
//       />
//       <path
//         d="M35.9965 43.4172C35.9965 44.8797 35.8753 46.1024 35.633 47.0854C35.3907 48.0444 34.9545 48.8955 34.3244 49.6387C33.1128 51.0293 30.7622 51.7245 27.2727 51.7245C23.8074 51.7245 21.1175 50.9454 19.2032 49.387C18.7912 47.3731 18.5852 45.3831 18.5852 43.4172C18.5852 39.4373 19.3364 35.877 20.8389 32.7363C22.3655 29.5955 24.1345 27.3659 26.1459 26.0472C28.9811 27.198 31.3317 29.3558 33.1976 32.5205C35.0635 35.6612 35.9965 39.2935 35.9965 43.4172ZM31.5982 41.7989C31.5982 38.1067 30.9803 34.942 29.7444 32.3047C27.3938 34.4385 25.5521 36.9079 24.2193 39.713C22.9108 42.5181 22.2565 45.2752 22.2565 47.9844C23.0804 48.1523 24.0618 48.2362 25.2008 48.2362C27.4302 48.2362 29.3446 47.9605 30.9439 47.409C31.3801 45.539 31.5982 43.6689 31.5982 41.7989Z"
//         fill="#333333"
//       />
//       <path
//         d="M42.5274 27.4498L61.1018 26.9463L60.9928 29.3558L55.9039 29.8593L53.6503 51.0053L49.6518 51.3649L47.8344 30.6504L42.7455 31.1539L42.5274 27.4498Z"
//         fill="#333333"
//       />
//       <path
//         d="M76.4923 51.4368L75.6563 42.0146L67.9866 42.626L67.1142 51.0053L63.4793 51.3649L61.4074 27.4857L69.695 26.9463L68.3137 39.8569L75.4745 39.9288L74.4204 27.5577L82.708 27.0182L80.1272 51.0772L76.4923 51.4368Z"
//         fill="#333333"
//       />
//       <path
//         d="M87.599 51.3649L85.5271 27.4857L94.1782 26.9463L91.5974 51.0053L87.599 51.3649Z"
//         fill="#333333"
//       />
//       <path
//         d="M117.139 27.1261L115.576 50.8614L112.304 51.4009L100.527 32.1249H100.236L100.345 50.8614L96.7104 51.0413L97.6918 27.4857L106.488 26.9463L112.849 44.8916H113.14L113.504 27.3059L117.139 27.1261Z"
//         fill="#333333"
//       />
//       <path
//         d="M127.149 51.3649C125.283 51.3649 123.55 51.1491 121.951 50.7176C120.376 50.286 119.164 49.7226 118.316 49.0273C117.904 47.1093 117.698 45.1194 117.698 43.0575C117.698 39.5332 118.316 36.2247 119.552 33.1319C120.787 30.0151 122.69 27.5337 125.258 25.6876L132.274 30.075C128.93 32.1848 126.276 34.8701 124.313 38.1307C122.35 41.3913 121.369 44.6759 121.369 47.9844C122.702 48.2482 124.01 48.38 125.295 48.38C126.603 48.38 127.779 48.2601 128.821 48.0204L128.094 40.1446L135 39.713L132.819 52.3719L129.257 52.6596L129.111 51.257C128.457 51.329 127.803 51.3649 127.149 51.3649Z"
//         fill="#333333"
//       />
//     </svg>
//   );
// };
