import { useEffect } from "react";
import { Home } from "pages/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GlobalState, RootStateType } from "store/types";

export const RootRouter = (): JSX.Element => {
  const { language } = useSelector<RootStateType, GlobalState>(
    (state) => state.global
  );
  const { i18n } = useTranslation("translation");

  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* // redirect to / */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
