import { combineReducers } from "redux";
import { ReduxAction, RootStateType } from "./types";
import { GlobalActionTypes } from "./actionTypes";
import { globalInitialState } from "./initialStates";

const globalReducer = (state = globalInitialState, action: ReduxAction) => {
  switch (action.type) {
    case GlobalActionTypes.SET_GLOBAL:
      return { ...state, ...action.payload };
    case GlobalActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload.language };
    case GlobalActionTypes.INCREMENT_NUMBER_OF_WORDS_PLAYED:
      return {
        ...state,
        numberOfWordsPlayed: (state.numberOfWordsPlayed ?? 0) + 1,
      };
    case GlobalActionTypes.INCREMENT_NUMBER_OF_WORDS_WON:
      return { ...state, numberOfWordsWon: (state.numberOfWordsWon ?? 0) + 1 };

    // email popup
    case GlobalActionTypes.TOGGLE_EMAIL_POPUP:
      return { ...state, showEmailPopup: !state.showEmailPopup };
    case GlobalActionTypes.SET_EMAIL_POPUP:
      return { ...state, showEmailPopup: action.payload.showEmailPopup };
    case GlobalActionTypes.TOGGLE_INSTRUCTIONS_SEQUENCE:
      return {
        ...state,
        showInstructionsSequence: !state.showInstructionsSequence,
      };
    case GlobalActionTypes.SET_INSTRUCTIONS_SEQUENCE:
      return {
        ...state,
        showInstructionsSequence: action.payload.showInstructionsSequence,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers<RootStateType>({
  global: globalReducer,
});

export default rootReducer;
