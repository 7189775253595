import { Word } from "api/types/api";

import "./styles.scss";
import { Logo } from "assets/logo";
import { useEffect, useState } from "react";
import { getFasterThanPercentage } from "utils/getFasterThanPercentage";
import {
  UilFacebook,
  UilMultiply,
  UilTwitter,
  UilWhatsapp,
} from "@iconscout/react-unicons";
import { SocialMedia } from "conf/socialMedia";
import { getShareLink } from "utils/getShareLink";
import { useTranslation } from "react-i18next";

interface Props extends React.PropsWithChildren {
  show: boolean;
  word: Word | null;
  timerValue: number;

  onHide: () => void;
}

export const SharePopup: React.FC<Props> = ({
  show,
  word,
  timerValue,
  onHide,
}) => {
  const { t } = useTranslation();

  const [fasterThanPercentage, setFasterThanPercentage] = useState(0);

  const onShare = (socialMedia: SocialMedia) => () => {
    if (!word) {
      return;
    }
    const shareLink = getShareLink({
      socialMedia,
      fasterThanPercentage,
      word,
      t,
    });
    window.open(shareLink, "_blank");
  };

  useEffect(() => {
    if (!show || !word) {
      return () => {};
    }

    const fasterThanPercentage = getFasterThanPercentage({ word, timerValue });
    setFasterThanPercentage(fasterThanPercentage);
  }, [show, word, timerValue]);
  return show && word ? (
    <div className="sharePopupWrapper">
      <div className="sharePopup">
        <button className="closeButton" onClick={onHide}>
          <UilMultiply className="icon" />
        </button>
        <div className="logo">
          <Logo />
        </div>
        <div className="congratulation">
          <h1>
            <span>{t("sharePopup.d0")}</span>
          </h1>
          <p>
            {t("sharePopup.d1")} {timerValue} {t("sharePopup.d2")}
          </p>
          <p className="fasterThanPercentage">
            {t("sharePopup.d3")} {fasterThanPercentage}% {t("sharePopup.d4")}
          </p>
        </div>

        <div className="shareSocialMedia">
          <p>{t("sharePopup.d5")}</p>
          <div className="socialMediaIcons">
            <button
              className="share-icon facebook"
              onClick={onShare(SocialMedia.Facebook)}
            >
              <UilFacebook className="icon" />
            </button>
            <button
              className="share-icon twitter"
              onClick={onShare(SocialMedia.Twitter)}
            >
              <UilTwitter className="icon" />
            </button>
            <button
              className="share-icon whatsapp"
              onClick={onShare(SocialMedia.Whatsapp)}
            >
              <UilWhatsapp className="icon" />
            </button>
            {/* <button
              className="share-icon snapchat"
              onClick={onShare(SocialMedia.Snapchat)}
            >
              <UilSnapchatGhost className="icon" />
            </button>
            <button
              className="share-icon telegram"
              onClick={onShare(SocialMedia.Telegram)}
            >
              <UilTelegram className="icon" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
