import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { RootRouter } from "routes/RootRouter";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RootRouter />
    </>
  );
}

export default App;
